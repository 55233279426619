import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import session from "./session";
import imagesReducer from "./images";
import CommentsReducer from "./comments";
import likesReducer from "./likes";
import followerReducer from "./followers";
import searchReducer from "./search";

const rootReducer = combineReducers({
  session,
  images: imagesReducer,
  comments: CommentsReducer,
  likes: likesReducer,
  followers: followerReducer,
  search: searchReducer,
});

let enhancer;

if (process.env.NODE_ENV === "production") {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require("redux-logger").default;
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
